<template>
    <div class="card card-custom example example-compact">
        <div class="card-header">
            <h3 class="card-title">{{title}}</h3>
            <button type="button" class="close" @click="cancelForm()">
                <i aria-hidden="true" class="ki ki-close"></i>
            </button>
        </div>

        <!--begin::Form-->
        <form class="form fv-plugins-bootstrap fv-plugins-framework" ref="dataForm" id="dataForm">
            <div class="card-body" style="max-height:500px;overflow:auto;">

                <div class="form-group table-responsive">
                    <table class="table ">
                        <thead>
                        <tr class="alert alert-light">
                            <th scope="col" width="20%">SKU</th>
                            <th scope="col" width="30%">发货详情</th>
                            <th scope="col" width="30%">收货详情</th>
                            <th scope="col">价格</th>
                            <th scope="col">调拨数量</th>
                        </tr>
                        </thead>
                        <tbody>

                        <template v-for="(item, idx) in data_form.sku">
                            <tr :key="idx" v-if="item.allot > 0">
                                <td>
                                    <div  class="text-dark-65 font-weight-bold line-height">
                                        {{item.sku}}
                                    </div>
                                    <div style="margin-top:10px;"  v-if="item.checked"><span class="label label-danger label-inline">盘</span></div>
                                    <div style="margin-top:10px;">
                                        <template v-for="attr in JSON.parse(item.attr_info)">
                                            <span :key="attr" class="label label-primary label-inline font-weight-lighter mr-2">{{attr}}</span>
                                        </template>
                                    </div>
                                </td>
                                <td>
                                    <div class="text-dark-65  line-height">
                                        发货仓库：{{data_form.from_warehouse_name}}
                                    </div>
                                    <div class="text-dark-65  line-height">
                                        货架：{{item.shelf_number}}
                                    </div>
                                    <div class="text-dark-65  line-height">
                                        物料编号：{{item.material_number}}
                                    </div>
                                    <div class="text-dark-65  line-height">
                                        创建时间：{{timeToDate(item.create_time)}} &nbsp;&nbsp;&nbsp;&nbsp; {{item.creator}}
                                    </div>
                                    <div class="text-dark-65  line-height" v-if="item.operate_time">
                                        修改时间：{{timeToDate(item.operate_time)}} &nbsp;&nbsp;&nbsp;&nbsp; {{item.operator}}
                                    </div>
                                    <div class="text-dark-65  line-height" v-if="item.purchasement_number">
                                        采购单号：{{item.purchasement_number}}
                                    </div>
                                </td>

                                <td>
                                    <div class="text-dark-65  line-height">
                                        收货仓库：{{data_form.to_warehouse_name}}
                                    </div>
                                    <div class="text-dark-65  line-height">
                                        货架：{{item.in_stock_detail.shelf_number}}
                                    </div>
                                    <div class="text-dark-65  line-height">
                                        物料编号：{{item.in_stock_detail.material_number}}
                                    </div>
                                    <div class="text-dark-65  line-height">
                                        创建时间：{{timeToDate(item.in_stock_detail.create_time)}} &nbsp;&nbsp;&nbsp;&nbsp; {{item.in_stock_detail.creator}}
                                    </div>
                                    <div class="text-dark-65  line-height" v-if="item.in_stock_detail.operate_time">
                                        修改时间：{{timeToDate(item.in_stock_detail.operate_time)}} &nbsp;&nbsp;&nbsp;&nbsp; {{item.in_stock_detail.operator}}
                                    </div>
                                    <div class="text-dark-65  line-height" v-if="item.in_stock_detail.purchasement_number">
                                        采购单号：{{item.in_stock_detail.purchasement_number}}
                                    </div>
                                </td>
                                <td>{{item.price}}</td>
                                <td>{{item.allot}}</td>

                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>



            </div>

            <div class="card-footer">
                <button ref="data_form_submit" class="btn btn-primary mr-2">保存</button>
                <button class="btn btn-secondary" type="reset" @click="cancelForm()">关闭</button>
            </div>
        </form>
        <!--end::Form-->
    </div>
</template>
<script>

    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
    import KTUtil from "@/assets/js/components/util";
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import Treeselect from '@riophae/vue-treeselect'

    import {getAllotDetail} from '@/api/warehouseallot';


    export default {
        name: "allot_show",
        props: {
            title: String,
            dialog_data: Object,
        },
        created:function() {
            var _this = this;

            getAllotDetail({'id': _this.dialog_data.form_data.id}).then(function(res) {
                if(res) {
                    _this.data_form.sku = res.response;
                }
            });
        },
        components :{

        },
        watch: {

        },
        data() {
            return {
                data_form:{
                    id: this.dialog_data.form_data.id,
                    to_warehouse_id: this.dialog_data.form_data.to_warehouse_id,
                    to_warehouse_name: this.dialog_data.form_data.to_warehouse_name,
                    from_warehouse_id: this.dialog_data.form_data.from_warehouse_id,
                    from_warehouse_name: this.dialog_data.form_data.from_warehouse_name,
                    sku: [

                    ]
                },
                warehouse_list: [],
                shelf_list: [],
                product_list: []
            }
        },
        mounted() {



        },
        methods: {
            cancelForm(){
                this.$emit('opeareResultHandler', 0);

            },
            regionChange (data) {
                this.data_form.struct_address = data;
            },
            onFloatInput(e){
                e.target.value = (e.target.value.match(/^\d*(\.?\d{0,2})/g)[0]) || null;
            },
            onIntInput(max, idx){
                var value = this.data_form.sku[idx].allot;
                value = parseInt(value) || null;
                if(value > max){
                    value = max;
                }
                this.data_form.sku[idx].allot = value;
            },
            timeToDate(value) {
                return KTUtil.timestamp2date(value);
            }
        }
    }
</script>
