<template>
    <v-app style="background: transparent" :class="{}">
        <template v-if="route_children">
            <router-view></router-view>
        </template>
        <template v-else>
            <!-- begin:: Content Head -->
            <KTSubheader
                    v-if="nav.subheaderDisplay"
                    v-bind:breadcrumbs="nav.breadcrumbs"
                    v-bind:title="nav.pageTitle"
            />
            <!-- end:: Content Head -->

            <div class="d-flex flex-column-fluid">
                <!--begin::Container-->
                <div class="container">

                    <!--begin::Card-->
                    <div class="card card-custom">
                        <div class="card-header flex-wrap border-0 pt-6 pb-0">
                            <div class="card-title">
                                <h3 class="card-label">调拨列表
                                    <span class="d-block text-muted pt-2 font-size-sm"></span>
                                </h3>
                            </div>
                            <div class="card-toolbar">

                                <!--begin::Button-->
                                <a href="javascript:;" class="btn btn-primary font-weight-bolder" @click="addAllot()">
                                    添加调拨
                                </a>
                                <!--end::Button-->
                            </div>
                        </div>
                        <div class="card-body">
                            <!--begin: Search Form-->

                            <!--begin::Search Form-->
                            <div class="mb-7">
                                <div class="row align-items-center">
                                    <div class="col-lg-9 col-xl-8">
                                        <div class="row align-items-center">
                                            <div class="col-md-4 my-2 my-md-0">
                                                <div class="d-flex align-items-center">
                                                    <label class="mr-3 mb-0 d-none d-md-block" style="width: 100px;">发货仓库:</label>
                                                    <b-form-select class="form-control" v-model="search_form.from_warehouse_id" :options="warehouse_list"  name="from_warehouse_id">

                                                    </b-form-select>
                                                </div>
                                            </div>
                                            <div class="col-md-4 my-2 my-md-0">
                                                <div class="d-flex align-items-center">
                                                    <label class="mr-3 mb-0 d-none d-md-block" style="width: 100px;">目的仓库:</label>
                                                    <b-form-select class="form-control" v-model="search_form.to_warehouse_id" :options="warehouse_list"  name="to_warehouse_id">

                                                    </b-form-select>
                                                </div>
                                            </div>
                                            <a href="#" class="btn btn-light-primary px-6 font-weight-bold" v-on:click="searchList()">Search</a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <!--end::Search Form-->
                            <!--end: Search Form-->


                            <KTDatatable
                                    v-bind:list="list"
                                    v-bind:column="column"
                                    v-bind:showSelect="false"
                                    v-bind:showPagination="true"
                                    v-bind:pageSize="page.limit"
                                    v-bind:pageCount="page.pageCount"
                                    v-bind:total="page.total"
                                    @operateHandler="operateHandler"
                                    @pagechangeHandler="pagechangeHandler"
                            ></KTDatatable>

                        </div>
                    </div>
                    <!--end::Card-->
                </div>
                <!--end::Container-->
            </div>

            <v-dialog v-model="dialog_show" persistent max-width="1200px">
                <component
                        :key="dialog_id"
                        :title="dialog_title"
                        :visible="dialog_show"
                        :is="dialog_view"
                        :dialog_data="dialog_data"
                        @opeareResultHandler="opeareResultHandler"></component>
            </v-dialog>
        </template>
    </v-app>

</template>
<style lang="css">
    @import "~@/assets/css/common.css";
</style>
<script>
    import KTSubheader from "@/view/layout/subheader/Subheader.vue";
    import KTDatatable from "@/view/content/tables/Datatable.vue";
    import KTUtil from "@/assets/js/components/util";
    import addAllot from '@/view/pages/warehouseallot/AddAllot';
    import updateAllot from '@/view/pages/warehouseallot/UpdateAllot';
    import receiveAllot from '@/view/pages/warehouseallot/ReceiveAllot';
    import showAllot from '@/view/pages/warehouseallot/ShowAllot';

    import {getWareHouse} from '@/api/warehouse';
    import {getAllotList, sendAllot, deleteAllot} from '@/api/warehouseallot';

    export default {
        name: "allot_view",
        components: {
            KTSubheader,
            KTDatatable
        },
        created: function(){
            var _this = this;

            //判断路由
            if('/' + this.$route.name == this.$route.path){
                _this.searchList();
            } else {
                _this.route_children = true;
            }

            getWareHouse().then(function(res){
                if(res && res.response){
                    _this.warehouse_list = KTUtil.listToSelectOptions(res.response, 'id', 'name');
                }
            });

        },
        data() {
            return {
                route_children: false,
                nav: {
                    subheaderDisplay: true,
                    breadcrumbs : [{'id':'', 'route':'/warehouseallot', 'title':'仓库调拨'}],
                    pageTitle: '仓库'
                },
                list: [],
                column: [{
                    field: 'allot_number',
                    title: '调拨编号',
                    width: 150,

                },{
                    field: 'from_warehouse_name',
                    title: '发货仓库',
                },{
                    field: 'to_warehouse_name',
                    title: '目的仓库',
                },{
                    field: 'status',
                    title: '状态',
                    formatter: function(row) {
                        if(row.status == 1){
                            return '<span class="text-dark-50">待发货</span>';
                        } else if(row.status == 2) {
                            return '<span class="text-primary">发货中</span>';
                        } else if(row.status == 3) {
                            return '<span class="text-danger">已收货</span>';
                        }
                    }
                },{
                    field: 'create_time',
                    title: '创建时间',
                    width: 150,
                    formatter: function(row) {
                        return KTUtil.timestamp2date(row.create_time);
                    }
                },{
                    field: 'operate',
                    title: '操作',
                    width: 150,
                    formatter: function(row){
                        let html = '';
                        if(row.status == 1){
                            html = '' +
                                '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="发货">'+
                                '<i class="la la-truck icon-lg"></i>'+
                                '</a>'+
                                '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="编辑">'+
                                '<i class="la la-edit icon-lg"></i>'+
                                '</a>'+
                                '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="删除">'+
                                '<i class="la la-trash-o icon-lg"></i>'+
                                '</a>';
                        } else if (row.status == 2){
                            html = '' +
                                '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="收货">'+
                                '<i class="la la-luggage-cart icon-lg"></i>'+
                                '</a>';
                        } else if (row.status == 3){
                            html = '' +
                                '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="详情">'+
                                '<i class="la la-clipboard-list icon-lg"></i>'+
                                '</a>';
                        }
                        return html;
                    }
                }],
                search_form: {
                    to_warehouse_id: '',
                    from_warehouse_id: ''
                },
                page: {
                    currPage: 1,
                    limit: 15,
                    pageCount:0,
                    total:0,
                },
                dialog_id: 0,
                dialog_show: false,
                dialog_title: '',
                dialog_view: null,
                dialog_data: null,
                warehouse_list: []
            }
        },
        watch: {
            $route: {
                handler(val, oldval) {
                    if('/' + this.$route.name == this.$route.path){
                        this.route_children = false;
                    } else {
                        this.route_children = true;
                    }
                },
                deep: true
            }
        },
        methods: {
            searchList() {
                var _this = this;
                var data = _this.search_form;
                data['page'] = _this.page.currPage;
                data['limit'] = _this.page.limit;
                getAllotList(data).then(function(res){
                    if(res){
                        _this.list = res.response.list;
                        _this.page.total = res.response.total;
                        _this.page.pageCount = res.response.count;
                    }
                });
            },
            operateHandler(clazz, row) {
                var _this = this;
                if(clazz.indexOf('la-clipboard-list') > -1){
                    //编辑操作
                    this.dialog_id = new Date().getTime();
                    this.dialog_show = true;
                    this.dialog_title = '调拨详情';
                    this.dialog_view = showAllot;
                    this.dialog_data = {
                        form_data: row
                    };
                } else if(clazz.indexOf('la-truck') > -1){

                    KTUtil.confirm('操作提示', '是否确认开始发货？', function(){
                        sendAllot({id: row.id}).then(function (res) {
                            if (res) {
                                KTUtil.toast(_this, '结果提醒', '发货成功', 'success');
                                _this.searchList();
                            }
                        });
                    });
                } else if(clazz.indexOf('la-edit') > -1){

                    this.dialog_id = new Date().getTime();
                    this.dialog_show = true;
                    this.dialog_title = '编辑调拨';
                    this.dialog_view = updateAllot;
                    this.dialog_data = {
                        form_data: row
                    };
                } else if(clazz.indexOf('la-trash-o') > -1){
                    KTUtil.confirm('操作提示', '是否确认删除该条记录？', function(){
                        deleteAllot({id: row.id}).then(function (res) {
                            if (res) {
                                KTUtil.toast(_this, '结果提醒', '删除成功', 'success');
                                _this.searchList();
                            }
                        });
                    });
                } else if(clazz.indexOf('la-luggage-cart') > -1){

                    this.dialog_id = new Date().getTime();
                    this.dialog_show = true;
                    this.dialog_title = '调拨收货';
                    this.dialog_view = receiveAllot;
                    this.dialog_data = {
                        form_data: row
                    };
                }
            },
            pagechangeHandler(page, pageSize) {
                this.page.currPage = page;
                this.page.limit = pageSize;
                this.searchList();
            },
            addAllot() {
                this.dialog_id = new Date().getTime();
                this.dialog_show = true;
                this.dialog_title = '添加调拨';
                this.dialog_view = addAllot;
                this.dialog_data = {

                };
            },

            opeareResultHandler(result) {
                if(result){
                    this.searchList();
                }
                this.dialog_show = false;
            }
        }
    }
</script>
