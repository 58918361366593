import request from "./request";

export function addAllot(params){
    return request({
        url: 'warehouseallot/addAllot',
        method: 'post',
        data: params
    });
}

export function getAllotList(params){
    return request({
        url: 'warehouseallot/getAllotList',
        method: 'post',
        data: params
    });
}

export function getAllotDetail(params){
    return request({
        url: 'warehouseallot/getAllotDetail',
        method: 'post',
        data: params
    });
}

export function updateAllot(params){
    return request({
        url: 'warehouseallot/updateAllot',
        method: 'post',
        data: params
    });
}


export function sendAllot(params){
    return request({
        url: 'warehouseallot/sendAllot',
        method: 'post',
        data: params
    });
}

export function deleteAllot(params){
    return request({
        url: 'warehouseallot/deleteAllot',
        method: 'post',
        data: params
    });
}

export function receiveAllot(params){
    return request({
        url: 'warehouseallot/receiveAllot',
        method: 'post',
        data: params
    });
}
